<template>
  <div id="quotations-audits">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <h5>Email Cotización</h5>
            <b-row>
              <b-col xl="3" sm="12">
                <b-form-input id="name"
                  v-model="emailQuotation"
                  name="name"
                  disabled />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <quotations-audits-list :audits="audits"></quotations-audits-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import QuotationsAuditsList from './components/QuotationsAuditsList'

  export default{
    name: 'QuotationsAuditsView',
    components: {QuotationsAuditsList},
    props: ['audits'],
    data() {
      return {
      }
    },
    computed: {
      emailQuotation () {
        return this.audits.customer ? this.audits.customer.email : ''
      },
    }
  }
</script>